import { useQuery } from '@tanstack/react-query'
import { StorageKey } from '@/client/types'
import { getPackageInfos } from '@/infra/services'

export const usePackageInfos = (locale: string = 'es') =>
  useQuery([StorageKey.PACKAGES_INFO, locale], {
    queryFn: () => getPackageInfos(locale),
    enabled: !!locale,
    staleTime: Infinity,
    keepPreviousData: true
})

